import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a className="sel" href="/radca/">
                    Radca
                  </a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/" className="sel">
              {" "}
              &gt; Radca
            </a>
          </div>
          <div
            id="page_content"
            style={{
              "padding-top": "0px"
            }}
          >
            <h1>
              <img src="/images/Radca_SK.png" />
            </h1>
            <div id="article_sort">
              <div className="category_switch">
                {/*<span className="men" />*/}
                <div className="filter" data-filter=".cat_1" data-hash="nadcha">
                  <span className="photo" style={{ backgroundImage:"url(/kcfinder/uploads/files/produkty/GettyImages-1007965142.jpg)"}}>
                    <span className="mask" />
                  </span>
                  <span className="name">NÁDCHA</span>
                </div>
                <div
                  className="filter"
                  data-filter=".cat_2"
                  data-hash="prechladnutie"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/shutterstock_571003300.jpg)"}}>
                    <span className="mask" />
                  </span>
                  <span className="name">PRECHLADNUTIE</span>
                </div>
                <div className="filter" data-filter=".cat_3" data-hash="deti">
                  <span className="photo" style={{ backgroundImage:"url(/kcfinder/uploads/files/produkty/shutterstock_105321398.jpg)"}}>
                    <span className="mask" />
                  </span>
                  <span className="name">DETI</span>
                </div>
              </div>
              <div className="wysiwyg">
                <p
                  style={{
                    "-webkit-text-align": "center",
                    "text-align": "center",
                    "font-wieght": "700",
                    "font-size": "18px"
                  }}
                >
                  Prečítajte si ďalšie užitočné informácie.
                </p>
              </div>
              <div id="article_list">
                <a
                  href="/radca/nadcha/ako_si_spravne_cistit_nos/"
                  className="box mix cat_1"
                  data-myorder="0"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg)"}} />
                  <span className="name">
                    Ako si správne čistiť nos pri nádche?
                  </span>
                </a>
                <a
                  href="/radca/nadcha/spoznajte_typy_nadchy/"
                  className="box mix cat_1"
                  data-myorder="1"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg)"}} />
                  <span className="name">Spoznajte typy nádchy</span>
                </a>
                <a
                  href="/radca/prechladnutie/domace_sposoby_boja_s_prechladnutim/"
                  className="box mix cat_2"
                  data-myorder="2"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/artykuly/GettyImages-1030072086.jpg)"}} />
                  <span className="name">
                    Domáce spôsoby boja s prechladnutím
                  </span>
                </a>
                <a
                  href="/radca/nadcha/zabojujte_s_nadchou_rychlo/"
                  className="box mix cat_1"
                  data-myorder="3"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/shutterstock_152527238.jpg)"}} />
                  <span className="name">Zabojujte s nádchou rýchlo</span>
                </a>
                <a
                  href="/radca/deti/upchaty_nos_u_detC3AD/"
                  className="box mix cat_3"
                  data-myorder="4"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/GettyImages-135538267.jpg)"}} />
                  <span className="name">Upchatý nos u detí</span>
                </a>
                <a
                  href="/radca/deti/imunita_dietata_dbajte_a_posilnujte/"
                  className="box mix cat_3"
                  data-myorder="5"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/shutterstock_1668728821.jpg)"}} />
                  <span className="name">
                    Imunita dieťaťa – dbajte a posilňujte
                  </span>
                </a>
                <a
                  href="/radca/nadcha/alergicka_nadcha_spoznajte_ju_a_predchadzajte_jej/"
                  className="box mix cat_1"
                  data-myorder="6"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/shutterstock_1206612040.jpg)"}} />
                  <span className="name">
                    Alergická nádcha – spoznajte ju a predchádzajte jej
                  </span>
                </a>
                <a
                  href="/radca/prechladnutie/styri_fazy_vzniku_prechladnutia/"
                  className="box mix cat_2"
                  data-myorder="7"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/shutterstock_87834502.jpg)"}} />
                  <span className="name">Štyri fázy vzniku prechladnutia</span>
                </a>
                <a
                  href="/radca/nadcha/co_je_nadcha/"
                  className="box mix cat_1"
                  data-myorder="8"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/shutterstock_680919733.jpg)"}} />
                  <span className="name">Čo je nádcha?</span>
                </a>
                <a
                  href="/radca/nadcha/co_je_zapal_prinosovych_dutin/"
                  className="box mix cat_1"
                  data-myorder="9"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/shutterstock_118647259.jpg)"}} />
                  <span className="name">Čo je zápal prinosových dutín?</span>
                </a>
                <a
                  href="/radca/nadcha/nasledky_neliecenej_nadchy/"
                  className="box mix cat_1"
                  data-myorder="10"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/shutterstock_754645981.jpg)"}} />
                  <span className="name">Následky neliečenej nádchy</span>
                </a>
                <a
                  href="/radca/prechladnutie/ako_sa_ochranit_pred_prechladnutim/"
                  className="box mix cat_2"
                  data-myorder="11"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/88688757.jpg)"}} />
                  <span className="name">
                    Ako sa ochrániť pred prechladnutím?
                  </span>
                </a>
                <a
                  href="/radca/deti/Boj_s_infekciami_nadcha_a_kasel_u_dietata/"
                  className="box mix cat_3"
                  data-myorder="12"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/shutterstock_494479543.jpg)"}} />
                  <span className="name">
                    Boj s infekciami – nádcha a kašeľ u dieťaťa
                  </span>
                </a>
                <a
                  href="/radca/nadcha/zvladnite_zapal_prinosovych_dutin/"
                  className="box mix cat_1"
                  data-myorder="13"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/shutterstock_181681637.jpg)"}} />
                  <span className="name">
                    Zvládnite zápal prinosových dutín
                  </span>
                </a>
                <a
                  href="/radca/nadcha/myty_o_nadche/"
                  className="box mix cat_1"
                  data-myorder="14"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/artykuly/Mity_o_katarze_03.jpg)"}} />
                  <span className="name">Mýty o nádche</span>
                </a>
                <a
                  href="/radca/deti/ako_naucit_dieta_siakat/"
                  className="box mix cat_3"
                  data-myorder="15"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/artykuly/dzieci_poradnik_03.jpg)"}} />
                  <span className="name">Ako naučiť dieťa siakať?</span>
                </a>
              </div>
            </div>
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
